.page-confirm {
    @extend %bg-gradient;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    width: 100vw;
    height: 100vh;
    padding: 100px 0 55vh;
    color: var(--color-white);

    > div {
        width: 80%;
        max-width: 550px;
        margin: auto;
    }

    &__title {
        @extend %default-font-bold;
        display: block;
        font-size: math.div(65, $base-font-size) + 0rem;
        line-height: math.div(75, $base-font-size) + 0rem;        
    }

    &__desc {
        margin-top: 20px;
        font-size: math.div(33, $base-font-size) + 0rem;
        line-height: math.div(40, $base-font-size) + 0rem;
    }

    &__form {
        margin-top: 50px;
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__row {
            display: flex;
            align-items: center;
            gap: 30px;
        }

        &__label,
        &__checkbox-label {
            @extend %default-font-medium;
            display: block;
            width: 250px;
            font-size: math.div(23, $base-font-size) + 0rem;
            text-align: left;
            line-height: 1.4;

            > span {
                display: block;
                max-width: 400px;
            }
        }

        &__checkbox-wrapper {
            width: 100%;
            margin-top: 30px;

            input {
                display: none;
            }
        }

        &__checkbox-label {
            position: relative;
            box-sizing: border-box;
            display: block;
            width: 100%;
            padding-right: 65px;
            cursor: pointer;

            > div {
                transform: translateY(-50%);
                position: absolute;
                top: 50%;
                right: 0;
                box-sizing: border-box;
                width: 45px;
                height: 45px;
                border: 4px solid var(--color-white);

                > div {
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 20px auto;
                }
            }

            &--confirm > div {
                border-color: var(--color-yellow);
            }
        }

        &__checkbox-wrapper input:checked ~ .form__checkbox-label {
            > div {
                > div {
                    opacity: 1;
                }
            }
        }

        &__input {
            box-sizing: border-box;
            display: block;
            width: 100%;
            padding: 12px 20px;
            color: var(--color-white);
            font-size: 20px;
            appearance: none;
            background: transparent;
            border: 4px solid var(--color-white);

            &:disabled {
                color: var(--color-yellow);
                background: rgba(0, 0, 0, 0.5);
                border-color: transparent
            }

            &.focus {
                border-color: var(--color-yellow);
            }
        }

        &__row--wide-label .form__label {
            width: calc(100% - 105px);
        }
    
        &__row--wide-label .form__input {
            width: 105px;
        }

        &__notice {
            @extend %default-font-medium;
            padding: 10px 20px;
            font-size: math.div(23, $base-font-size) + 0rem;
            text-align: center;
            line-height: 1.4;
            color: var(--color-black);
            background: var(--color-yellow);
        }
    }
}