.instruction-list {
    display: grid;
    gap: 30px 100px;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90%;
    max-width: 1000px;
    margin-inline: auto;

    &--2col {
        grid-template-columns: 1fr 1fr;
    }

    .visitor-type {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        max-width: 320px;
        padding: 0;
        appearance: none;
        cursor: pointer;
        outline: none;
        border: 0;
        border-radius: 0;

        &__image-wrapper {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            font-size: 0;
            line-height: 0;

            img {
                box-sizing: border-box;
                width: 100%;
                height: auto;
                border-radius: 50%;
            }
        }

        &__name {
            @extend %default-font;
            @include box-shadow(0px, 2px, 3px, 0, rgba(0, 0, 0, 0.3));
            position: relative;
            z-index: 1;
            display: block;
            margin-top: -40px;
            padding: 15px 30px;
            text-align: center;
            font-size: math.div(24, $base-font-size) + 0rem;
            line-height: math.div(28, $base-font-size) + 0rem;
            border-radius: 8px;
            background: var(--color-yellow);
        }

        &__expired-badge,
        &__certified-badge {
            @extend %default-font-medium;
            @include box-shadow(0px, 2px, 6px, 0, rgba(0, 0, 0, 0.3));
            position: relative;
            z-index: 2;
            margin-top: -2px;
            padding: 6px 12px 8px 43px;
            color: var(--color-white);
            background: #28c552;
            border-radius: 8px;

            span {
                transform: translateY(-50%);
                position: absolute;
                top: 50%;
                left: -7px;
                display: block;
                content: '';
                width: 35px;
                height: 35px;
                background-color: #28c552;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 20px;
                border: 4px solid #62e212;
                border-radius: 50%;
            }
        }

        &__expired-badge {
            background: var(--color-red);

            span {
                background-color: var(--color-red);
                background-size: 15px;
                border-color: #e74848;
            }
        }

        &__certified-status {
            display: block;
            margin-top: 10px;
            padding: 3px 8px;
            color: var(--color-white);
            font-size: math.div(12, $base-font-size) + 0rem;
            line-height: math.div(16, $base-font-size) + 0rem;
            background: var(--color-blue-dark);
            border-radius: 6px;
        }
    }

    &.instruction-list--size-small {
        width: 100%;
        max-width: 680px;
        gap: 30px 80px;

        .visitor-type {
            &__image-wrapper {
                width: 70%;
            }
    
            &__name {
                margin-top: -25px;
                padding: 8px 22px;
                font-size: math.div(18, $base-font-size) + 0rem;
                line-height: math.div(22, $base-font-size) + 0rem;
            }

            &__expired-badge,
            &__certified-badge {
                margin-top: 10px;
                padding: 4px 9px 5px 37px;
                font-size: math.div(14, $base-font-size) + 0rem;
                line-height: math.div(18, $base-font-size) + 0rem;

                span {
                    width: 28px;
                    height: 28px;
                    background-size: 14px;
                }
            }
        }
    }
}