@use 'sass:math';

.menu {
    @extend %bg-gradient;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &.show {
        display: flex;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 80px;

        li {
            display: inline-block;
            
            button {
                @extend %default-font-bold;
                display: block;
                padding: 0;
                color: var(--color-yellow);
                font-size: math.div(60, $base-font-size) + 0rem;
                line-height: 1;
                border: 0;
                border-radius: 0;
                background: transparent;
                outline: none;
                cursor: pointer;
            }
        }
    }
}