.page-title {
    @extend %default-font-bold;
    position: fixed;
    z-index: 5;
    top: 40px;
    left: 0;
    display: block;
    padding: 23px 70px;
    color: var(--color-white);
    text-transform: uppercase;
    font-size: math.div(38, $base-font-size) + 0rem;
    line-height: 1;
    background: var(--color-blue);
    border-radius: 0 0 25px 0;
    appearance: none;
    border: 0;
    outline: none;
    cursor: pointer;

    &--light {
        color: var(--color-blue);
        background: var(--color-white);
    }
}