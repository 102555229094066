.page-success {
    @extend %bg-gradient;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    text-align: center;
    color: var(--color-white);

    > div {
        width: 80%;
        max-width: 1300px;
        margin-inline: auto;
    }

    &__title {
        @extend %default-font-bold;
        display: block;
        max-width: 900px;
        margin-inline: auto;
        font-size: math.div(65, $base-font-size) + 0rem;
        line-height: math.div(75, $base-font-size) + 0rem;        
    }

    &__desc {
        margin-top: 40px;
        color: var(--color-yellow);
        font-size: math.div(33, $base-font-size) + 0rem;
        line-height: math.div(40, $base-font-size) + 0rem;
    }
}