@use 'sass:math';

.default-styles {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend %default-font-bold;
        display: block;
        margin: 0 0 4px;
        font-size: math.div(20, $base-font-size) + 0rem;
    }

    h1 {
        font-size: math.div(25, $base-font-size) + 0rem;
    }

    h2 {
        font-size: math.div(22, $base-font-size) + 0rem;
    }

    p,
    ul,
    ol {
        margin: 0 0 1em;
        line-height: 1.5;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul,
    ol {
        margin-left: 26px;
        line-height: 1.7;
    }

    ul {
        li {
            list-style-type: disc;
        }
    }

    ol {
        li {
            list-style-type: decimal;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    iframe {
        width: 100%;
        height: auto;
    }

    blockquote {
        display: block;
        margin: 0 0 1em;
        padding: 20px 28px;
        font-size: math.div(20, $base-font-size) + 0rem;
        font-style: italic;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .alignleft {
        float: left;
        margin: 1em 1.6em 1em 0;
    }

    .alignright {
        float: right;
        margin: 1em 0 1em 1.6em;
    }

    .aligncenter {
        display: block;
        margin: 1em auto;
    }
}