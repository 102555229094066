@use 'sass:math';

.page-profile {
    display: flex;
    width: 100%;
    height: 100%;

    &__left {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding: 100px;
    }

    &__right {
        @extend %bg-gradient;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 170px 100px 100px;

        &--center {
            justify-content: center;
        }

        &--align-top {
            padding-bottom: 200px;
            justify-content: center;
        }
    }

    &__instruction-wrapper {
        display: flex;
        flex-direction: column;
        gap: 45px;
        color: var(--color-white);

        .title {
            @extend %default-font-bold;
            display: block;
            font-size: math.div(55, $base-font-size) + 0rem;
            line-height: math.div(58, $base-font-size) + 0rem;
        }

        .description {
            margin-top: 30px;

            p,
            ul,
            ol {
                font-size: math.div(27, $base-font-size) + 0rem;
                line-height: math.div(40, $base-font-size) + 0rem;
            }
        }
    }

    .profile-welcome {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__top-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
            width: 100%;

            &--horizontal {
                flex-direction: row;
            }
        }

        &__img {
            box-sizing: border-box;
            width: 90%;
            max-width: 380px;
            overflow: hidden;
            font-size: 0;
            line-height: 0;
            border-radius: 50%;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__name {
            @extend %default-font;
            display: block;
            margin-top: 50px;
            color: var(--color-blue-dark-2);
            font-size: math.div(50, $base-font-size) + 0rem;
            line-height: math.div(50, $base-font-size) + 0rem;

            &--small {
                font-size: math.div(40, $base-font-size) + 0rem;
                line-height: math.div(48, $base-font-size) + 0rem;
                font-weight: 400;
            }

            img {
                width: 50px;
                height: auto;
                margin-left: 20px;
            }
        }

        &__not-you {
            margin-top: 15px;
            color: var(--color-blue-dark-2);
            font-size: math.div(20, $base-font-size) + 0rem;
            line-height: math.div(28, $base-font-size) + 0rem;
        }

        &__meta {
            margin-top: 50px;
            color: var(--color-blue-dark-2);
            font-size: math.div(25, $base-font-size) + 0rem;
            line-height: math.div(33, $base-font-size) + 0rem;
        }
    }

    &__registration-form {
        fieldset {
            display: block;
            margin: 0;
            padding: 0;
            border: 0;

            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        .input-wrapper {
            margin-bottom: 35px;

            &:last-child {
                margin-bottom: 0;
            }

            input {
                width: 100%;
            }
        }

        .lang-wrapper {
            display: flex;
            align-items: center;
            gap: 20px;

            span {
                @extend %default-font-bold;
                display: block;
                margin-right: 10px;
                color: var(--color-white);
                opacity: 0.75;
                font-size: math.div(22, $base-font-size) + 0rem;
                line-height: 1;
            }

            label {
                @extend %default-transition;
                position: relative;
                display: block;
                height: 30px;
                font-size: 0;
                line-height: 0;
                cursor: pointer;
                user-select: none;

                &::after {
                    @extend %default-transition;
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    bottom: -5px;
                    left: -5px;
                    display: block;
                    content: '';
                    border: 3px solid var(--color-white);
                    opacity: 0;
                }

                img {
                    width: auto;
                    height: 100%;
                }
            }

            input:checked+label {
                transform: scale(1.1);

                &::after {
                    opacity: 1;
                }
            }

            input {
                display: none;
            }
        }
    }
}