@use 'sass:math';

.page-instructions {
    @extend %bg-gradient;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 110px;

    &__title {
        @extend %default-font-bold;
        display: block;
        width: 80%;
        margin-inline: auto;
        margin-bottom: 80px;
        color: var(--color-white);
        text-align: center;
        font-size: math.div(60, $base-font-size) + 0rem;
    }
}