@use 'sass:math';

.page-instruction {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background: var(--color-real-black);

    &__loading-icon {
        @extend %bg-gradient;
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        > img {
            width: 45px;
            height: auto;
            animation: loadingIconAnimation 5s linear infinite;
        }
    }

    &__video-wrapper {
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        overflow: hidden;
        width: 100vw;
        aspect-ratio: 16/9;
    }

    &__video {
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
    }

    &__question-overlay {
        position: absolute;
        z-index: 1;
        right: 7.6vw;
        bottom: 8.8vw;
        left: 7.6vw;
        display: none;
        grid-template-columns: 1fr 1fr;
        gap: 5.6vw;
        opacity: 0;

        &.count-3 {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1.5vw;
        }

        &.show {
            display: grid;
        }

        .button {
            display: block;
            width: 100%;
            height: 19vw;
            font-size: 0;
            line-height: 0;
            border: none;
            border-radius: 0;
            outline: none;
            appearance: none;
            cursor: pointer;
            opacity: 1;
            border-radius: 20px;
            border: 3px solid transparent;

            &:hover {
                border-color: white;
            }

            &.button-1 {
                background: rgba(0, 0, 255, 0.5);
            }

            &.button-2 {
                background: rgba(255, 0, 0, 0.5);
            }

            &.button-3 {
                display: none;
                background: rgba(255, 255, 0, 0.5);
            }
        }

        &.count-3 .button {
            &.button-3 {
                display: block;
            }
        }
    }

    &--dev &__question-overlay {
        opacity: 1;
    }

    .side-btn {
        z-index: 2;
    }

    .stop-screen {
        @extend %bg-gradient;
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none;
        justify-content: center;
        align-items: center;

        &.show {
            display: flex;
        }

        &__inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 85%;
            max-width: 1100px;
            margin-inline: auto;
        }

        &__title {
            @extend %default-font-bold;
            color: var(--color-white);
            font-size: math.div(70, $base-font-size) + 0rem;
            line-height: math.div(80, $base-font-size) + 0rem;
            text-align: center;
        }

        &__btns {
            display: flex;
            align-items: center;
            gap: 60px;
            margin-top: 125px;
        }
    }

    @keyframes loadingIconAnimation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}