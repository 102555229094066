@use 'sass:math';

.input {
    @extend %default-font-bold;
    @include border-radius-single(0);
    display: block;
    box-sizing: border-box;
    padding: 15px 25px;
    color: var(--color-white);
    font-size: math.div(22, $base-font-size) + 0rem;
    line-height: normal;
    outline: none;
    border: 2px solid var(--color-white);
    border-radius: 0;
    appearance: none;
    background: var(--color-blue);

    &::-webkit-input-placeholder {
        color: var(--color-white);
        opacity: 0.8;
    }

    &:-moz-placeholder {
        color: var(--color-white);
        opacity: 0.8;
    }

    &::-moz-placeholder {
        color: var(--color-white);
        opacity: 0.8;
    }

    &:-ms-input-placeholder {
        color: var(--color-white);
        opacity: 0.8;
    }

    &.focus {
        border-color: var(--color-yellow);
    }
}
