// Global colors
:root {
    --color-black: #3f3f3f;
    --color-real-black: #000;
    --color-white: white;
    
    --color-main-text: var(--color-black);

    --color-blue: #1cafe6;
    --color-blue-rgb: 28, 175, 230;
    --color-blue-dark: #0877a7;
    --color-blue-dark-rgb: 8, 119, 167;
    --color-blue-dark-2: #0E84C7;
    --color-yellow: #ffde00;
    --color-green: #7FDB46;
    --color-green-rgb: 127, 219, 70;
    --color-red: #d80000;
    --color-red-rgb: 216, 0, 0;
}

%bg-gradient {
    background: linear-gradient(90deg, var(--color-blue) 50%, var(--color-blue-dark) 100%);
}