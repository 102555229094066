.control-btns {
    position: absolute;
    z-index: 10;
    bottom: 20px;
    left: 20px;
    display: flex;
    gap: 15px;

    button {
        position: relative;
        display: block;
        width: 45px;
        height: 45px;
        padding: 0;
        font-size: 0;
        line-height: 0;
        text-indent: -9999px;
        appearance: none;
        outline: none;
        cursor: pointer;
        color: black;
        border-top: 2px solid black;
        border-right: 2px solid black;
        border-bottom: 4px solid black;
        border-left: 2px solid black;
        border-radius: 10px;
        background-color: #efefef;
        background-repeat: no-repeat;
        background-position: center center;

        &.escape {
            background-size: 60%;
        }

        &.reload {
            background-size: 55%;
        }
    }
}