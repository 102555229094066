$base-font-size: 16;

%default-font {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
}

%default-font-medium {
    @extend %default-font;
    font-weight: 600;
}

%default-font-bold {
    @extend %default-font;
    font-weight: 700;
}