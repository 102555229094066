body,
html {
    width: 100%;
    height: 100%;
}

html {
    font-size: $base-font-size + 0px;
}

body {
    @extend %default-font;
    color: var(--color-main-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;

    &:fullscreen,
    &::backdrop {
        background-color: rgba(255,255,255,0);
    }
}

a {
    color: inherit;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

strong {
    @extend %default-font-bold;
}