.keyboard-wrapper {
    transform: translateX(-50%);
    position: fixed;
    bottom: -100%;
    left: 50%;
    width: 100%;
    max-width: 850px;
    color: var(--color-black);

    &::before {
        position: absolute;
        z-index: -1;
        top: 0;
        left: -50vw;
        display: block;
        content: '';
        width: 200vw;
        height: 100%;
        background: var(--color-black);
        opacity: 0.1;
        pointer-events: none;
    }

    &--numeric {
        max-width: 300px;
    }

    &.show {
        bottom: 0;
        opacity: 1;
        pointer-events: initial;
    }

    .hg-theme-default .hg-button {
        height: 66px;
    }
}