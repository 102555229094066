.page-start {
    position: relative;
    z-index: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: var(--color-blue);

    &__bg-video {
        transform: scale(1.01);
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        font-size: 0;
        line-height: 0;

        &::before,
        &::after {
            position: absolute;
            z-index: 1;
            display: block;
            content: '';
            pointer-events: none;
            background: var(--color-blue);
        }

        &::before {
            bottom: 0;
            left: 0;
            width: 100%;
            height: 90px;
        }

        &::after {
            top: 0;
            right: 0;
            width: 90px;
            height: 100%;
        }

        video {
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
        }
    }

    &__languages {
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 60px;
        width: 65%;
        max-width: 950px;
        margin-inline: auto;

        li {
            display: block;
        }

        button {
            display: block;
            padding: 0;
            appearance: none;
            border: 0;
            border-radius: 0;
            background: transparent;

            * {
                pointer-events: none;
            }
        }

        .img {
            position: relative;
            font-size: 0;
            line-height: 0;

            &::before {
                position: absolute;
                z-index: 1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: block;
                content: '';
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2) 100%);
            }

            img {
                position: relative;
                width: 100%;
                height: auto;
    
                &::before {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: red;
                }
            }
        }

        span {
            display: block;
            margin-top: 30px;
            color: var(--color-white);
            text-transform: uppercase;
            font-size: math.div(27, $base-font-size) + 0rem;
            line-height: 1;
        }
    }

    &__start-btn {
        @extend %default-transition;
        @extend %default-font-bold;
        @include box-shadow(5px, 5px, 20px, 0, rgba(0, 0, 0, 0.1));
        position: absolute;
        z-index: 1;
        bottom: 30px;
        right: 30px;
        box-sizing: border-box;
        display: block;
        width: 205px;
        height: 205px;
        color: var(--color-black);
        font-size: math.div(45, $base-font-size) + 0rem;
        line-height: 1;
        text-transform: uppercase;
        appearance: none;
        border: 0;
        border-radius: 50%;
        outline: none;
        border: 25px solid var(--color-white);
        background: var(--color-yellow);

        &:hover {
            @include box-shadow(5px, 5px, 30px, 0, rgba(0, 0, 0, 0.2));
        }
    }

    @media (min-width: 1400px) and (min-height: 800px) {
        &__start-btn {
            bottom: 35px;
            right: 35px;
            width: 260px;
            height: 260px;
            font-size: math.div(50, $base-font-size) + 0rem;
            border: 35px solid var(--color-white);
        }
    }

    @media (min-width: 1500px) and (min-height: 900px) {
        &__bg-video {
            &::before {
                height: 115px;
            }

            &::after {
                width: 115px;
            }
        }

        &__start-btn {
            bottom: 55px;
            right: 55px;
            width: 320px;
            height: 320px;
            font-size: math.div(70, $base-font-size) + 0rem;
            border: 35px solid var(--color-white);
        }
    }
}