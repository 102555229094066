.btn {
    @extend %default-transition;
    @extend %default-font-bold;
    display: inline-block;
    box-sizing: border-box;
    padding: 15px 20px 18px;
    color: var(--color-main-text);
    font-size: 1.2rem;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    color: var(--color-blue);
    border: 2px solid var(--color-blue);
    border-radius: 0 0 25px 0;
    background: transparent;
    appearance: none;
    user-select: none;

    &:hover,
    &:active,
    &:focus {
        color: var(--color-white);
        background: var(--color-blue);
    }

    &--nowrap {
        white-space: nowrap;
    }

    &--low {
        padding-top: 10px;
        padding-bottom: 13px;
    }

    &--small {
        padding: 15px 17px;
        font-size: 1.1rem;
    }

    &--large {
        padding: 17px 20px;
        font-size: 1.3rem;
    }

    &--solid-green {
        color: var(--color-black);
        border-color: var(--color-green);
        background: var(--color-green);

        &:hover,
        &:active,
        &:focus {
            color: var(--color-white);
            background: var(--color-green);
        }
    }

    &--solid-yellow {
        color: var(--color-black);
        border-color: var(--color-yellow);
        background: var(--color-yellow);

        &:hover,
        &:active,
        &:focus {
            color: var(--color-white);
            background: var(--color-yellow);
        }
    }
}