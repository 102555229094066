.final-screen {
    @extend %bg-gradient;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    color: var(--color-white);
    justify-content: center;
    align-items: center;
    text-align: center;

    &__title {
        @extend %default-font-bold;
        display: block;
        width: 90%;
        max-width: 1100px;
        margin-inline: auto;
        font-size: math.div(60, $base-font-size) + 0rem;
        line-height: 1;
    }

    &__desc {
        display: block;
        width: 90%;
        max-width: 700px;
        margin-top: 60px;
        margin-inline: auto;
        font-size: math.div(25, $base-font-size) + 0rem;
    }

    &__btns {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 70px;
    }
}