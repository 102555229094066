@use 'sass:math';

.side-btn {
    @extend %default-transition;
    @extend %default-font-bold;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 35px;
    box-sizing: border-box;
    display: block;
    min-width: 250px;
    padding: 20px 40px;
    color: var(--color-yellow);
    text-transform: uppercase;
    font-size: math.div(25, $base-font-size) + 0rem;
    letter-spacing: -0.4px;
    border-radius: 0 0 0 25px;
    border: 4px solid var(--color-yellow);
    border-right: 0;
    cursor: pointer;
    outline: none;
    appearance: none;

    &:hover,
    &:focus,
    &:active {
        color: var(--color-blue);
        background-color: var(--color-yellow);
    }

    &--left {
        right: auto;
        left: 0;
        bottom: 105px;
        color: var(--color-black);
        border: 4px solid var(--color-yellow);
        border-left: 0;
        border-radius: 0 0 25px;
        background: var(--color-yellow);
    }
}