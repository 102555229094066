.page-scan {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background: var(--color-white);

    &__languages {
        position: absolute;
        z-index: 1;
        top: 40px;
        right: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 15px;
        margin-inline: auto;

        li {
            display: block;
            width: 50px;
        }

        button {
            display: block;
            padding: 0;
            appearance: none;
            border: 0;
            border-radius: 0;
            background: transparent;
            opacity: 0.6;

            * {
                pointer-events: none;
            }

            &.current {
                opacity: 1;
            }
        }

        .img {
            position: relative;
            font-size: 0;
            line-height: 0;

            &::before {
                position: absolute;
                z-index: 1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: block;
                content: '';
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2) 100%);
            }

            img {
                position: relative;
                width: 100%;
                height: auto;
    
                &::before {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: red;
                }
            }
        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
    }

    &__right {
        @extend %bg-gradient;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 40%;
    }

    .scan-instruction {
        width: 90%;
        max-width: 650px;
        margin-inline: auto;

        &__img {
            max-width: 450px;
            margin-inline: auto;
            font-size: 0;
            line-height: 0;
            pointer-events: none;
            user-select: none;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__title {
            @extend %default-font-bold;
            display: block;
            max-width: 500px;
            margin-top: 50px;
            margin-inline: auto;
            color: var(--color-blue);
            font-size: math.div(48, $base-font-size) + 0rem;
            line-height: math.div(50, $base-font-size) + 0rem;
            text-align: center;
        }

        &__subtitle {
            display: block;
            margin-top: 80px;
            text-align: center;
            font-size: math.div(29, $base-font-size) + 0rem;
            line-height: math.div(36, $base-font-size) + 0rem;
        }
    }

    &__scanner {
        width: 500px;
        height: 500px;
    }

    &__scan-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin-inline: auto;
    }

    &__start-scanning-msg {
        position: relative;
        width: 90%;
        max-width: 350px;
        margin-inline: auto;
        margin-top: 20px;
        padding: 15px 30px;
        text-align: center;
        background: var(--color-white);

        &::before {
            transform: translateX(-50%) rotate(-45deg);
            position: absolute;
            bottom: 100%;
            left: 50%;
            display: block;
            content: '';
            margin-bottom: -10px;
            border-top: 20px solid var(--color-white);
            border-left: 20px solid transparent;
        }

        p {
            margin: 0;
            padding: 0;
            font-size: math.div(22, $base-font-size) + 0rem;
            line-height: 1.5;
        }
    }

    &__video-overlay {
        fill: none;
        stroke: #e9b213;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    &__video-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 100%;

        video {
            transform: translate(-50%, -50%) scale(1.01)!important;
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 50%;
            width: auto;
            height: 100%;
            background: white;
        }

        svg {
            transform: translate(-50%, -50%);
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            width: auto;
            height: 60%;
            pointer-events: none;
        }
    }

    &__video-loading-icon {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);

        > div {
            transform: translate(-50%, -50%);
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            width: 45px;
            height: 45px;
            display: block;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            animation: loadingIconAnimation 5s linear infinite;
        }
    }

    &__video-success-icon {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 200px;
        background-color: rgba(var(--color-green-rgb), 0.2);
        border: 25px solid var(--color-green);
        backdrop-filter: blur(5px);
    }

    &__error-icon {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 200px;
        background-color: rgba(var(--color-red-rgb), 0.2);
        border: 25px solid var(--color-red);
        backdrop-filter: blur(5px);
    }

    &__enter-code {
        display: block;
        margin-top: 80px;
        padding: 0;
        color: var(--color-yellow);
        font-size: math.div(28, $base-font-size) + 0rem;
        line-height: 1.5;
        border: 0;
        border-radius: 0;
        cursor: pointer;
        appearance: none;
        text-decoration: underline;
    }

    &__code-entry-wrapper {
        width: 80%;
        max-width: 400px;
        margin-inline: auto;
    }

    .code-entry-wrapper {
        display: none;

        &.show {
            display: block;
        }

        &__input {
            width: 100%;
        }

        &__invalid-code-msg {
            margin-top: 20px;
            padding: 15px 20px;
            color: var(--color-white);
            background: var(--color-red);
        }
    }

    @keyframes loadingIconAnimation {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }

        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
}