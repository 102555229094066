// Box shadow
@mixin box-shadow($hLength, $vLength, $blur, $spread, $color) {
    -webkit-box-shadow: $hLength $vLength $blur $spread $color;
    box-shadow: $hLength $vLength $blur $spread $color;
}


// Text shadow
@mixin text-shadow($hLength, $vLength, $blur, $color) {
    text-shadow: $hLength $vLength $blur $color;
}